export default [
  {
    title: 'navigation.dashboard',
    route: 'dashboard',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'DEFAULT',
  },
  {
    title: 'navigation.hangar_dashboard',
    route: 'hangar-dashboard',
    icon: 'BarChart2Icon',
    action: 'DASHBOARD_HANGAR_VIEW',
  },
  {
    title: 'navigation.mail_templates',
    route: 'mail-template',
    icon: 'MailIcon',
    action: 'OPERATOR_MAIL_VIEW',
  },
  // {
  //   title: 'navigation.chat',
  //   route: 'chat',
  //   icon: 'MessageSquareIcon',
  //   action: 'CHAT_EDIT',
  // },
  {
    title: 'order.title',
    pln: 0,
    route: 'OrdersManager',
    icon: 'DollarSignIcon',
    action: 'TRIP_ACCOUNTING_VIEW',
  },
  {
    title: 'offer.title',
    pln: 0,
    route: 'offers',
    icon: 'FilePlusIcon',
    action: 'OPERATOR_SUBSCRIPTION_VIEW',
  },
  {
    title: 'navigation.organizations',
    route: 'organizations',
    icon: 'UsersIcon',
    action: 'OPERATOR_ORGANIZATION_VIEW',
  },
  {
    title: 'navigation.informations',
    icon: 'InfoIcon',
    children: [
      {
        title: 'navigation.organization.title',
        route: { name: 'organization', params: { tab: 'main-information' } },
        iconAwesome: 'id-card-alt',
        action: 'ORGANIZATION_ACCOUNT_VIEW',
      },
      {
        title: 'navigation.account',
        route: { name: 'account', params: { tab: 'main-information' } },
        iconAwesome: 'user-cog',
        action: 'read',
        resource: 'DEFAULT',
      },
    ],
  },
  {
    title: 'navigation.contracts',
    icon: 'FolderIcon',
    children: [
      {
        title: 'cancellation_policy.title',
        pln: 0,
        route: 'cancellationPolicies',
        iconAwesome: 'money-check-alt',
        action: 'CANCELLATION_POLICY_VIEW',
      },
      {
        title: 'price_management.title',
        pln: 0,
        route: 'pricesManagement',
        iconAwesome: 'money-check-alt',
        action: 'PRICE_MANAGEMENT_VIEW',
      },
      {
        title: 'negotiated_rate.title',
        pln: 0,
        route: 'negotiated-rates',
        iconAwesome: 'percent',
        action: 'NEGOTIATED_RATE_VIEW',
      },
    ],
  },
  {
    title: 'navigation.translations_management',
    route: 'translations',
    iconAwesome: 'language',
    action: 'OPERATOR_TRANSLATION_VIEW',
  },
  {
    title: 'navigation.trips',
    route: 'trips',
    iconAwesome: 'suitcase',
    action: 'TRIP_VIEW',
  },
  {
    title: 'navigation.operator.trips',
    route: 'operator_trips',
    iconAwesome: 'suitcase',
    action: 'OPERATOR_TRIP_VIEW',
  },
  {
    title: 'navigation.slot_management',
    route: 'slot-management',
    iconAwesome: 'check-to-slot',
    action: 'TRIP_SLOT_MANAGEMENT_VIEW',
  },
  {
    title: 'navigation.organization_aircrafts',
    route: 'organization-aircrafts',
    iconAwesome: 'plane',
    action: 'ORGANIZATION_ACCOUNT_AIRCRAFT_VIEW',
  },
  {
    title: 'navigation.organization_helicopters',
    route: 'organization-helicopters',
    iconAwesome: 'helicopter',
    action: 'ORGANIZATION_ACCOUNT_HELICOPTER_VIEW',
  },
  {
    title: 'navigation.crew_management',
    route: 'crews',
    iconAwesome: 'user',
    action: 'CREW_VIEW',
  },
  {
    title: 'navigation.services',
    route: 'operator_services',
    iconAwesome: 'list',
    action: 'OPERATOR_SERVICE_VIEW',
  },
  {
    title: 'navigation.service_management',
    icon: 'ShuffleIcon',
    children: [
      {
        title: 'navigation.services',
        route: 'services',
        iconAwesome: 'list',
        action: 'SERVICE_VIEW',
      },
      {
        title: 'navigation.product.add',
        route: 'serviceAdd',
        iconAwesome: 'plus',
        action: 'SERVICE_EDIT',
      },
    ],
  },
  {
    title: 'navigation.categories',
    route: 'categories',
    iconAwesome: 'stream',
    action: 'OPERATOR_CATEGORY_VIEW',
  },
  {
    title: 'navigation.accounting',
    route: 'accountingList',
    iconAwesome: 'clipboard',
    action: 'OPERATOR_ACCOUNTING_VIEW',
  },
  {
    title: 'navigation.attributes',
    route: 'attributes',
    iconAwesomeBrand: 'elementor',
    action: 'OPERATOR_ATTRIBUTE_VIEW',
  },
  {
    title: 'navigation.parameters',
    route: 'parametersProducts',
    iconAwesome: 'cog',
    action: 'OPERATOR_PARAMETER_VIEW',
  },
]
